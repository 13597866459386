import CATEGORIES from '@/temp-configs/category-constants'

const sharedConfig = [
    {
        key: '365-night-home-trial',
        icon: 'icon-house',
        text: '365-night home trial',
        link: '/trial'
    },
    {
        key: 'free-delivery-setup',
        icon: 'icon-setup',
        text: <span><b className='t-bold'>Free</b> delivery &amp; setup</span>,
        link: '/delivery'
    },
    {
        key: 'free-mattress-removal',
        icon: 'icon-truck',
        text: <span><b className='t-bold'>Free</b> mattress removal</span>,
        link: '/delivery'
    },
    {
        key: 'lifetime-warranty',
        icon: 'icon-warranty',
        text: 'Lifetime warranty',
        link: '/warranty#saatva-mattress-lifetime-warranty'
    }
]

const valuePropsConfig = {
    homepage: sharedConfig,
    favorites: sharedConfig,
    qualityMaterials: sharedConfig,
    sale: sharedConfig,
    [CATEGORIES.FURNITURE]: sharedConfig,
    [CATEGORIES.MATTRESSES]: sharedConfig,
    [CATEGORIES.FURNITURE]: [
        sharedConfig[0],
        {
            key: 'free-delivery-assembly',
            icon: 'icon-assembly',
            text: <span><b className='t-bold'>Free</b> delivery & assembly</span>,
            link: '/delivery'
        },
        {
            key: 'free-foundation-removal',
            icon: 'icon-truck',
            text: <span><b className='t-bold'>Free</b> foundation/box spring removal</span>,
            link: '/delivery'
        },
        {
            key: 'lifetime-warranty',
            icon: 'icon-warranty',
            text: 'Lifetime warranty',
            link: '/warranty'
        }
    ],
    [CATEGORIES.BEDDING]: [
        {
            key: '45-day-free-return',
            text: '45-day free returns',
            icon: 'icon-house',
            link: '/returns'
        },
        {
            key: 'free-shipping-returns',
            text: <span><b className='t-bold'>Free</b> shipping</span>,
            icon: 'icon-truck',
            link: '/delivery'
        },
        {
            key: '24/7-customer-service',
            text: '24/7 customer service',
            icon: 'icon-headphones',
            link: '/help'
        },
        {
            key: '1-year-limited-warranty',
            text: '1-year limited warranty',
            icon: 'icon-warranty',
            link: '/warranty'
        }
    ]
}

export default valuePropsConfig