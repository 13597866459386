export const GET_PRODUCTS_DATA_QUERY = ` 
    query ProductsData($category: Categories) {
        getProductsData(category: $category) {
            sku
            title
            subtitle
            tag
            isNew
            launchDay
            tooltip
            defaultSwatch
            category
            hasShopCta
            hasLearnMoreCta
            hasCompareCta
            hasAddToCartCta
            details {
                label
                key
                name
                features
                description
            }
            quickviewSections {
                name
                content
            }
            ardadDescriptors {
                slot
                descriptors
            }
        }
    }
`


export const GET_PRODUCT_DATA_BY_TAGS_QUERY = `
    query ProductDataByTags($tags: [String]) {
        getProductDataByTags(tags: $tags) {
            sku
            title
            subtitle
            tag
            isNew
            launchDay
            ardadDescriptors {
                slot
                descriptors
            }
        }
    }
`
