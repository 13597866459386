import { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useProductState, useAttributeOptions } from '@saatva-bits/pattern-library.modules.selection'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import { Loader } from '@saatva-bits/pattern-library.components.loader'

import { getDetailTileArdadImages, getSwatches } from '@/utils/product'
import { getBadgeText } from '@/utils/discount'
import { affirmRefresh } from '@/utils/affirm'

import styles from './DetailProductTileSlider.module.scss'

const handleAffirmRefresh = (refresh) => {
    if (refresh) {
        affirmRefresh(100)
    }
}

const ProductTile = ({
    product,
    customImageProps,
    showAffirm,
    hideBadge,
    hideSwatches,
    badgeTextVersion
}) => {
    const { lowestPrice, highestPrice, productCode, category, subcategory, title, subtitle } = product

    const { getDiscounts } = useGetDiscounts()
    const productState = useProductState(productCode, ['assets', 'genericName', 'sku', 'productCode'])
    const { isTablet } = useBreakpoints('tablet')
    const { isV1: isFavoriteIconEnabled } = useExperiment('EXP.FAVORITES.DIS-530')


    const baseProduct = {
        sku: product.sku,
        parentSku: product.parentSku || product.sku,
        category: product.category,
        quantity: 1,
        isPrimaryProduct: true
    }

    const { finalPrice: lowestPriceDiscount } = getDiscounts([{ ...baseProduct, price: lowestPrice }])
    const { finalPrice: highestPriceDiscount, discountAmount: highestDiscountAmount } = getDiscounts([{ ...baseProduct, price: highestPrice }])

    const priceRange = {
        fullPriceMin: lowestPrice,
        fullPriceMax: highestPrice,
        finalPriceMin: lowestPriceDiscount || 0,
        finalPriceMax: highestPriceDiscount || 0,
    }

    const options = useAttributeOptions(productCode, product.swatchAttribute)

    const onSwatchChange = (swatch) => {
        options.setAttributeValue(swatch)
    }

    const swatches = hideSwatches ? null : getSwatches(options, options?.selectedValue, onSwatchChange, productCode)
    const { defaultImage, hoverImage } = getDetailTileArdadImages(product.ardadDescriptors, productState, '3-2', customImageProps)
    const { defaultImage: thumbnailImage } = getDetailTileArdadImages(product.ardadDescriptors, productState, '1-1')

    if (!defaultImage.name || !hoverImage.name) {
        return null
    }

    const badgeText = getBadgeText(highestDiscountAmount, badgeTextVersion)

    return (
        <DetailProductTile
            containerClassName={styles.tile}
            title={{ text: title, href: product.url, titleClassName: styles.title }}
            subtitle={{ text: subtitle, className: styles.subtitle }}
            imageHref={product.url}
            priceRange={priceRange}
            swatches={swatches}
            defaultImage={defaultImage}
            hoverImage={hoverImage}
            imageBadgeLeft={badgeText && !hideBadge ? { text: badgeText, kind: 'promo', position: 'topLeft' } : undefined}
            affirm={showAffirm && !isTablet && { price: lowestPriceDiscount }}
            productCode={productCode}
            category={category}
            subCategory={subcategory}
            thumbnailImage={thumbnailImage}
            location='Rec Strip Product Tile'
            enableFavorites={isFavoriteIconEnabled}
        />
    )
}

const DetailProductTileSlider = ({
    products,
    title,
    sectionClassName,
    titleClassName,
    dataSelector,
    isCart = false,
    simpleSliderProps = {},
    customImageProps,
    showAffirm = false,
    hideBadge = false,
    hideSwatches = false,
    badgeTextVersion = 'exact',
    isLoading = false,
    hideSliderSideButtons = false
}) => {
    useEffect(() => {
        handleAffirmRefresh(showAffirm)
    })

    if (isEmpty(products) && isLoading === false) {
        return null
    }

    const sliderContent = products.map(product => (
        <ProductTile
            key={product.productCode}
            product={product}
            customImageProps={customImageProps}
            showAffirm={showAffirm}
            hideBadge={hideBadge}
            hideSwatches={hideSwatches}
            badgeTextVersion={badgeTextVersion}
        />
    )).filter(Boolean)

    const hideSliderControlsStyles = classNames({
        [styles.hideSliderControls]: products.length < 4,
        [styles.manualHideSliderButtons]: hideSliderSideButtons
    })
    const leftButtonClasses = classNames(styles.buttons, styles.leftButton, hideSliderControlsStyles)
    const rightButtonClasses = classNames(styles.buttons, styles.rightButton, hideSliderControlsStyles)
    const pageDtosAndNumbersClasses = classNames(styles.paginationDots, hideSliderControlsStyles)

    const sliderProps = {
        isCart,
        sliderLabel: dataSelector,
        infinite: true,
        pageDotsAndNumbers: true,
        dotsClassName: pageDtosAndNumbersClasses,
        leftButtonClassName: leftButtonClasses,
        rightButtonClassName: rightButtonClasses,
        ...simpleSliderProps
    }

    const sectionClassNames = classNames('section', styles.section, sectionClassName)
    const titleClassNames = classNames('t-heading2 t-color', styles.sectionTitle, titleClassName)
    const defaultSliderClassNames = classNames(styles.defaultSlider,
        { [styles.twoItemsSlider]: products.length === 2 },
        { [styles.oneItemSlider]: products.length === 1 }
    )

    return (
        <section className={sectionClassNames} data-selector={dataSelector}>
            <div className="container">
                {title &&
                    <h2 className={titleClassNames}>
                        {title}
                    </h2>
                }
                {isLoading &&
                    <Loader
                        className={styles.loaderContainer}
                        loaderItemCount={4}
                        size="md"
                        isInline={true}
                    />
                }
                {!isLoading &&
                    <>
                        <div className={styles.mobileSlider}>
                            <SimpleSlider
                                overflow={'hidden'}
                                onChange={() => handleAffirmRefresh(showAffirm)}
                                {...sliderProps}
                                shownSize={1}
                            >
                                {sliderContent}
                            </SimpleSlider>
                        </div>
                        <div className={defaultSliderClassNames}>
                            <SimpleSlider
                                shownSize={3}
                                overflow={'hidden'}
                                onChange={() => handleAffirmRefresh(showAffirm)}
                                {...sliderProps}
                            >
                                {sliderContent}
                            </SimpleSlider>
                        </div>
                    </>
                }
            </div>
        </section>
    )
}

DetailProductTileSlider.propTypes = {
    products: PropTypes.array.isRequired,
    title: PropTypes.string,
    sectionClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    dataSelector: PropTypes.string,
    isCart: PropTypes.bool,
    simpleSliderProps: PropTypes.object,
    showAffirm: PropTypes.bool,
    hideBadge: PropTypes.bool,
    hideSwatches: PropTypes.bool,
    hideSliderSideButtons: PropTypes.bool,
}

export default DetailProductTileSlider
