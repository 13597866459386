import omit from 'lodash/omit'
import { getColorNameForImages } from '@saatva-bits/pattern-library.utils.product'
import { getAssetForVariant } from '@saatva-bits/pattern-library.modules.selection'
import logger from '@saatva-bits/pattern-library.utils.logger'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

/**
 * Adds sortKeys and adds product and compare URIs
 *
 * @param {object} product
 * @returns
 */
export const formatProductDetails = (product) => {
    let details = {}
    product.details && product.details.forEach((detail, index) => {
        const key = detail.key
        const name = detail.name
        const features = omit(detail, ['key', 'name'])

        // This key (feature, firmness) has been added so we push into content array!
        if (details[key] && details[key].content && details[key].content.length > 0) {
            details[key].content.push(features)
        } else {
            details = {
                ...details,
                [key]: {
                    sortOrder: index,
                    key,
                    name,
                    content: [
                        features
                    ]
                }
            }
        }
    })
    const detailsList = Object.values(details).sort((a, b) => a.sortOrder - b.sortOrder)

    const shopCta = product.hasShopCta ? `/${product.category}/${product.sku}` : null
    const compareCta = product.hasCompareCta ? `/${product.category}/compare?sku=${product.sku}` : null

    return {
        details: detailsList,
        shopCta,
        compareCta
    }
}

/**
 * Build an object from an array of attributes for easy data access
 * @param {Object[]} attributes
 * @param {String} attributes.name
 * @param {String} attributes.value
 */
const buildAttributeObject = (attributes) => {
    if (!attributes) {
        return {}
    }

    const contentAttributeValues = {}

    attributes.forEach(attribute => {
        contentAttributeValues[attribute]

        if (contentAttributeValues[attribute.name]) {
            contentAttributeValues[attribute.name].push(attribute.value)
        } else {
            contentAttributeValues[attribute.name] = [attribute.value]
        }
    })

    return contentAttributeValues
}

/**
 * This is done to make variant filtering work, we add parent level attributes to each variant
 * to not change the implementation
 * @param {Object[]} variants
 * @param {Object} content
 * @param {Object[]} filterConfig
 */
const enrichVariantsWithFilterData = (variants, content, filterConfig) => {
    const contentAttributeValues = buildAttributeObject(content.attributes)

    for (let variant of variants) {
        for (const config of filterConfig) {
            // Only enrich parent filters
            if (config.target === 'parent') {
                for (const attributePath of config.attributePaths) {
                    // only enrich if its not overriding property from plytix
                    if (!variant[attributePath]) {
                        // first check the new attributes array froming from prismic
                        // then use the legacy attribute definition
                        if (contentAttributeValues[attributePath]) {
                            variant[attributePath] = contentAttributeValues[attributePath]
                        } else if (content[attributePath]) {
                            variant[attributePath] = content[attributePath].map(valueObj => valueObj.value)
                        }
                    }
                }
            }
        }
    }
    return variants
}

/**
 * Merges prismic product content with catalog product data
 *
 * @param {object} contentProduct
 * @param {object} catalogProduct
 * @param {object[]?} filterConfig
 * @returns
*/
export const formatProduct = (contentProduct, catalogProduct, filterConfig = []) => {
    return {
        ...contentProduct,
        ...catalogProduct,
        ...catalogProduct.content,
        ...formatProductDetails(contentProduct),
        variants: enrichVariantsWithFilterData(catalogProduct.variants, contentProduct, filterConfig)
    }
}

/**
 * Format the swatch name. Fixes an error where getting the color twice returns an invalid color.
 *
 * @param {string} swatch
 * @returns {string}
*/
export const formatSwatch = swatch => {
    let formattedSwatch = swatch.toLowerCase()
    switch (formattedSwatch) {
        case 'natural oak':
            formattedSwatch = 'natural oak'
            break
        default:
            formattedSwatch = getColorNameForImages(formattedSwatch)
            break
    }

    return formattedSwatch
}

/**
 * Get the product swatches
 *
 * @param {array} options - Swatches values
 * @param {string} currentSwatch - Current selected swatch
 * @param {function} onChange - Triggered when a swatch is changed
 * @param {string} productCode - Product Code
 * @returns {{
 *  useInverseCheckColor: boolean,
 *  size: 'small' | 'medium',
 *  initialSelection: string,
 *  productCode: string,
 *  optionsList: { value: string, label: string, background: string, attributeCode: string }[],
 *  onChange: function
 * }}
*/
export const getSwatches = (options, currentSwatch, onChange, productCode) => {
    if (!options) {
        return null
    }

    return {
        useInverseCheckColor: true,
        size: 'small',
        initialSelection: currentSwatch,
        productCode,
        optionsList: options.values.map(value => {
            return {
                attributeCode: value.code,
                background: value.swatch,
                label: value.label,
                value: value.code
            }
        }) || [],
        onChange
    }
}

export const getArdadAssetFromVariant = (variant, descriptor, ratio) => {
    let asset = { folder: null, file: null, alt: null }
    try {
        asset = getAssetForVariant(variant, descriptor, ratio)
    } catch (error) {
        logger.error({
            message: 'Cannot find ARDAD asset for variant',
            location: 'utils/product.js getArdadAssetFromVariant',
            error,
            details: {
                productCode: variant.productCode,
                sku: variant.sku,
                descriptor
            },
            enableClientAlerts: true,
        })
    }

    return asset
}

/**
 * Get the ARDAD images for the product tiles
 *
 * @param {array} ardadDescriptors - ARDAD descriptors set in Prismic
 * @param {object} selectedVariant - The product variant where the assets are obtained
 * @param {string} ratio - Ratio for the image
 * @param {object} imageProps - Props for the Imgix Component
 * @returns {{
 *   defaultImage: { folder: string, name: string, alt: string, prefixOverride: { mobile: string, tablet: string, desktop: string }, imgixDomain: string, widths: { mobile: number }, lazyLoad: boolean, lazyLoadOffset: number },
 *   hoverImage: { folder: string, name: string, alt: string, prefixOverride: { mobile: string, tablet: string, desktop: string }, imgixDomain: string, widths: { mobile: number }, lazyLoad: boolean, lazyLoadOffset: number }
 * }}
*/
export const getDetailTileArdadImages = (
    ardadDescriptors,
    selectedVariant,
    ratio = '3-2',
    imageProps = {
        widths: { mobile: 348 },
        lazyLoad: true,
        lazyLoadOffset: null // Override the lazyload offset added by the detail product tile from bit
    }
) => {
    const [ardadDefaultImage, ardadHoverImage] = ['product-tile', 'product-tile-hover'].map(slot => {
        const descriptor = ardadDescriptors.find(descriptorDef => descriptorDef.slot === slot)?.descriptors?.[0]

        if (!descriptor) {
            logger.error({
                message: 'Cannot find prismic defined descriptor for slot',
                location: 'utils/product.js getDetailTileArdadImages',
                details: {
                    productCode: selectedVariant.productCode,
                    sku: selectedVariant.sku,
                    slot
                },
                enableClientAlerts: true,
            })

            return {
                ...imageProps,
                folder: '',
                name: '',
                alt: ''
            }
        }

        const { folder, file } = getArdadAssetFromVariant(selectedVariant, descriptor, ratio)
        return {
            ...imageProps,
            folder,
            name: file,
            alt: selectedVariant.genericName,
            prefixOverride: {
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            },
            imgixDomain: IMGIX_URL
        }
    })

    return {
        defaultImage: ardadDefaultImage,
        hoverImage: ardadHoverImage
    }
}

/**
 * Get the ARDAD images for the product tiles
 *
 * @param {array} ardadDescriptors - ARDAD descriptors set in Prismic
 * @param {object} selectedVariant - The product variant where the assets are obtained
 * @param {string} ratio - Ratio for the image
 * @param {object} imageProps - Props for the Imgix Component
 * @returns {{
*   defaultImage: { folder: string, name: string, alt: string, prefixOverride: { mobile: string, tablet: string, desktop: string }, imgixDomain: string, widths: { mobile: number }, lazyLoad: boolean, lazyLoadOffset: number },
*   hoverImage: { folder: string, name: string, alt: string, prefixOverride: { mobile: string, tablet: string, desktop: string }, imgixDomain: string, widths: { mobile: number }, lazyLoad: boolean, lazyLoadOffset: number }
* }}
*/
export const getDetailModalArdadImages = (
    ardadDescriptors,
    selectedVariant,
    ratio = '3-2',
    imageProps = {
        widths: { mobile: 348 },
        lazyLoad: true,
        lazyLoadOffset: null // Override the lazyload offset added by the detail product tile from bit
    }
) => {
    const carouselDescriptors = ardadDescriptors?.find(descriptorDef => descriptorDef.slot === 'carousel')?.descriptors || []
    const filteredDescriptors = carouselDescriptors.filter(descriptor => descriptor != 'video' && descriptor != 'ar')
    return filteredDescriptors.map(descriptor => {
        
        let asset = { folder: null, file: null }
        try {
            asset = getAssetForVariant(selectedVariant, descriptor, ratio)
        } catch (error) {
            logger.info({
                message: 'Cannot find ARDAD asset for this variant',
                location: 'utils/product.js',
                error,
                details: {
                    productCode: selectedVariant.productCode,
                    sku: selectedVariant.sku,
                    descriptor: descriptor
                }
            })
        }
        return {
            ...imageProps,
            descriptor,
            folder: asset.folder,
            name: asset.file,
            alt: selectedVariant.genericName,
            prefixOverride: {
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            },
            imgixDomain: IMGIX_URL
        }
    }).filter(props => props.folder && props.name)
}

/**
 * Gets the default attribute values for a product's variants
 * @param {Object} product - The product object
 * @param {Array<Object>} product.options - Array of product options
 * @param {string} product.options[].code - Option identifier (e.g. 'size', 'color', 'quantity')
 * @param {string} product.options[].default - Default attribute value for this option
 * @returns {Object} An object mapping option codes to their default values (e.g. {size: 'Standard/Queen', color: 'White'})
 */
const getDefaultVariantAttributes = (product) => {
    return (product.options || []).reduce((acc, option) => {
        acc[option.code] = option.default
        return acc
    }, {})
}

/**
 * Extracts selected filter attributes from an array of filters
 * @param {Object[]} selectedFilters - Array of filter objects
 * @param {string[]} selectedFilters[].attributePaths - Array of attribute paths (e.g. ['color', 'fabric'])
 * @param {string[]} selectedFilters[].matchingValues - Array of selected values (e.g. ['Ivory', 'Ivory Boucle'])
 * @returns {Object.<string, string[]>} An object mapping attribute paths to their matching values
 * @example
 * // Returns:
 * {
 *   size: [],
 *   types: ['Blankets And Quilts'],
 *   materials: [],
 *   fabric: ['Ivory', 'Ivory Boucle'],
 *   color: ['Ivory', 'Ivory Boucle'],
 *   inStock: []
 * }
 */
const getSelectedFilterAttributes = (selectedFilters) => {
    return selectedFilters.reduce((acc, filter) => {
        filter.attributePaths.forEach(attributePath => {
            acc[attributePath] = filter.matchingValues
        })
        return acc
    }, {})
}

/**
 * Calculates points for a variant based on how well it matches default and selected attributes
 * Higher points are given to variants that match more selected/default attributes
 * Additional weighting is given based on the position in the selected filters list
 * 
 * @param {Object} variant - The variant to score
 * @param {Object.<string, string>} defaultVariantAttributes - Map of default attribute values
 * @param {Object.<string, string[]>} selectedFilterAttributes - Map of attribute names to their selected values
 * @returns {number} Point score for the variant
 */
const getVariantPoints = (variant, defaultVariantAttributes, selectedFilterAttributes) => {
    const points = Object.keys(selectedFilterAttributes).reduce((acc, attribute) => {
        // If the variant doesn't have the attribute, no additional points
        if (!variant[attribute]) {
            return acc
        }

        const variantValue = variant[attribute]
        const isDefaultMatch = Array.isArray(variantValue) ? variantValue.includes(defaultVariantAttributes[attribute]) : variantValue === defaultVariantAttributes[attribute]
        const selectedValues = selectedFilterAttributes[attribute] || []
        const isSelectedMatch = Array.isArray(variantValue) ? variantValue.some(value => selectedValues.includes(value)) : selectedValues.includes(variantValue)

        // If this variant doesn't match the default or selected attributes, no additional points
        if (!isDefaultMatch && !isSelectedMatch) {
            return acc
        }

        // If it matches a selected attribute value, give bonus points based on position
        // The attributes are ordered by the order that they are selected by the user
        // We want to give more points to variants that match the users first selected attributes
        if (isSelectedMatch) {
            const index = selectedValues.findIndex(value => value === variantValue)
            const positionBonus = (selectedValues.length - index) / selectedValues.length
            return acc + 1 + positionBonus
        }

        // If it only matches default, give 1 point
        return acc + 1
    }, 0)

    return points
}

/**
 * Determines the best matching variant attributes based on selected filters
 * If no filters are selected, returns default variant attributes
 * Otherwise scores variants based on how well they match selected filters
 * 
 * @param {Array} products - Array of product objects
 * @param {Array} selectedFilters - Array of selected filter objects
 * @returns {Object} Map of product codes to their best matching variant attributes
 */
export const getDefaultVariantAttributeUpdates = (products, selectedFilters) => {
    const productUpdates = {}

    // No need to update the products if no filters are selected
    if (selectedFilters.length === 0) {
        return {}
    }

    products.forEach(product => {
        const defaultVariantAttributes = getDefaultVariantAttributes(product)
        const selectedFilterAttributes = getSelectedFilterAttributes(selectedFilters)

        // Score each variant based on matches to selected filters and defaults
        const variantsWithPoints = product.matchingVariants.map(variant => {
            const points = getVariantPoints(variant, defaultVariantAttributes, selectedFilterAttributes)
            return {
                ...variant,
                points
            }
        })

        // Sort by points descending and take the best match
        const sortedVariants = variantsWithPoints.sort((a, b) => b.points - a.points)
        const selectedVariant = sortedVariants[0]

        // Extract the configurable attributes from best matching variant
        const selectedVariantAttributes = product.configurableAttributes.reduce((acc, attribute) => {
            acc[attribute] = selectedVariant[attribute]
            return acc
        }, {})

        productUpdates[product.productCode] = selectedVariantAttributes
    })

    return productUpdates
}
