import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import valuePropsConfig from './ValuePropsConfig'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

import styles from './ValuePropsSection.module.scss'

const ValuePropsSection = ({ configKey = 'homepage', background = 'transparent', className }) => {
    const valueProps = valuePropsConfig[configKey]

    const sectionClassName = classNames(
        styles.valueProps,
        styles[`valueProps-${background}`],
        className
    )

    return (
        <div className={sectionClassName}>
            <div className="container">
                <div data-selector='value-props-panel' className={classNames(styles.row, 'row u-flexJustify--center u-flexJustify--lg-spaceBetween')}>
                    {valueProps.map(({ key, icon, text, link }) => (
                        <div key={key} className={`${styles.valueProp} col col--xs-6 col--md-3`} data-selector='value-prop-icon'>
                            {link ? (
                                <a href={link} className={styles.link}>
                                    <div className={styles.iconWrapper}>
                                        <SvgSprite className={styles.icon} spriteID={icon} />
                                    </div>
                                    {text}
                                </a>
                            ) : (
                                <>
                                    <div className={styles.iconWrapper}>
                                        <SvgSprite className={styles.icon} spriteID={icon} />
                                    </div>
                                    {text}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

ValuePropsSection.propTypes = {
    configKey: PropTypes.string,
    className: PropTypes.string,
    background: PropTypes.oneOf(['transparent', 'contrast-1'])
}

export default ValuePropsSection
