const content = `
    shortDescription
`
const magentoBundleOptions = `
    id
    quantity
    value
`

const attributeSets = `
    size
    comfortLevel
    mattressType
    mattressHeight
    pillowHeightOption
    sidePanel
    chairOption
    quiltOption
    color
    fabric
    material
    foundationHeight
    bathSet
    scent
`

const bundledVariants = `
    bundledVariants {
        productCode
        sku
        quantity
    }
`

const attribute = `
    code
    label
    value
`

const variant = `
    sku
    productCode
    name
    genericName
    category
    subcategory
    productUrl
    variantUrl
    price
    ${attributeSets}
    fabricMaterial
    magentoProductType
    inStock
    magentoBundleOptions {
        ${magentoBundleOptions}
    }
    hiddenAttributeValues {
        ${attributeSets}
    }
    attributes {
        ${attribute}
    }
    firmnesses
    sleepPositions
    ${bundledVariants}
    assets {
        ardad {
            descriptor
            fileExt
            filenameBase
            folder
        }
    }
`

const product = `
    active
    productCode
    name
    category
    subcategory
    defaultPrice
    lowestPrice
    highestPrice
    attributeSets {
        ${attributeSets}
    }
    swatchAttribute
    url
    content {
        ${content}
    }
    relationships {
        relationshipLabel
        relatedProductCodes
    }
    variants {
        ${variant}
    }
    magentoProductType
    options {
        code
        displayName
        displayType
        default
        values {
            code
            label
            swatch
        }
    }
    configurableAttributes
`

export const GET_CATALOG_PRODUCTS_QUERY = `
    query ($productCodes: [String!]!, $modifiers: [ProductModifier]) {
        products(productCodes: $productCodes, modifiers: $modifiers) {
            ${product}
        }
    }
`

export const GET_CATEGORY_PRODUCTS_QUERY = `
    query ($category: CategoryEnum!, $modifiers: [ProductModifier]) {
        productsByCategory (category: $category, modifiers: $modifiers) {
            name
            products {
                ${product}
            }
        }
    }
`

export const GET_VARIANTS_QUERY = `
    query ($skus: [String!]!) {
        variants(skus: $skus) {
            ${variant}
        }
    }
`

export const GET_TRENDING_ITEMS_QUERY = `
    query GetTrendingProducts($exclusions: RecommendationExclusions) {
        getTrendingProducts(exclusions: $exclusions) {
            ${product}
        } 
    }
`